<template>
  <!-- 收付款账户信息 -->
  <div class="bank">
    <el-table
      :data="list"
      style="width: 100%"
      border
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column label="户名" prop="cardholder"></el-table-column>
      <el-table-column label="开户行" prop="bankName"></el-table-column>
      <el-table-column label="账户" prop="cardNumber"></el-table-column>
      <el-table-column label="是否有合同" prop="izSignContract">
        <template slot-scope="{ row }">
          <span v-if="row.izSignContract == 1">有</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="{ row }">
          <el-button size="small" @click="handeChecked(row)" type="text">{{row.isChecked ? '取消选择' : '选择'}}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  // 收付款账户信息
  name: 'paymentAccount',
  props: {
    list: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      headerRowStyle: { color: '#333333' },
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
    };
  },
  methods: {
    handeChecked(row) {
      this.$emit('click', row.id);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
