<template>
  <!-- 收付款账户信息 -->
  <div class="bank">
    <el-table
      :data="list"
      style="width: 100%"
      border
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column label="户名" prop="cardholder"></el-table-column>
      <el-table-column label="开户行" prop="bankName"></el-table-column>
      <el-table-column label="账户" prop="cardNumber"></el-table-column>
      <el-table-column :render-header="renderHeader" label="联行号、(提现使用)" prop="bankCode"></el-table-column>
      <el-table-column min-width="100px" :render-header="renderHeader" label="开户行许可证/收款银行确认函、(提现使用)" prop="attachmentJsonList">
        <template slot-scope="{ row }">
          <el-button size="small" @click="openAttachment(row.attachmentJsonList)" type="text" :disabled="row.attachmentJsonList.length == 0">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="是否有合同" prop="izSignContract">
        <template slot-scope="{ row }">
          <span v-if="row.izSignContract == 1">有</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="{ row }">
          <!-- :disabled="row.izSignContract == 0" -->
          <el-button size="small" @click="openDialog(row)" type="text">{{ row.izSignContract == 1 ? '修改提现信息' : '编辑' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="修改收付款账号信息"
      :visible.sync="dialogVisible"
      width="1000px"
      @close="closeDialog"
    >
      <el-form
        :model="form"
        :rules="rules"
        label-width="150px"
        ref="dialogForm"
        v-loading="dialogLoading"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="开户行" prop="bankName">
              <el-input
                v-model.trim="form.bankName"
                :maxlength="30"
                placeholder="请输入开户行"
                :disabled="disabledEdit && !!cloneDeepForm.bankName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户名" prop="cardholder">
              <el-input
                v-model.trim="form.cardholder"
                :maxlength="20"
                placeholder="请输入开户名"
                :disabled="disabledEdit && !!cloneDeepForm.cardholder"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号" prop="cardNumber">
              <el-input
                v-model.trim="form.cardNumber"
                :maxlength="30"
                placeholder="请输入账号"
                :disabled="disabledEdit && !!cloneDeepForm.cardNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联行号">
              <el-input
                v-model.trim="form.bankCode"
                :maxlength="20"
                placeholder="请输入联行号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <ul slot="label" class="custom-label">
                <li>开户行许可证/</li>
                <li>收款银行确认函</li>
              </ul>
              <PictureCardCrypto accept=".doc,.docx,.pdf,.png,.jpeg,.jpg" :limit="2" :maxSize="2000 * 1024" v-model="form.attachmentJson" :isHhow="false"></PictureCardCrypto>
              <div class="tip">最多上传2张,最大2M</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 填写说明 -->
            <illustrateExplain />
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="onSubmit" type="primary">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="previewVisible" title="查看附件" width="50%">
      <el-carousel :autoplay="false" height="500px">
        <el-carousel-item :key="item" v-for="item in attachmentJsonList" class="carousel-item">
          <a v-if="isNotImgFile(item)" class="file-link" :href="item">{{item}}</a>
          <img :src="item" class="carousel-img" v-else/>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import { updateBankCard } from '@/api/subject-information';
import PictureCardCrypto from '@/components/Upload/PictureCardCrypto';
import illustrateExplain from '@/components/illustrate-explain';
import cloneDeep from 'lodash/cloneDeep';
export default {
  // 收付款账户信息
  name: 'account-information',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabledEdit: Boolean,
  },
  components: {
    PictureCardCrypto,
    illustrateExplain,
  },
  data() {
    return {
      attachmentJsonList: [],
      whiteList: ['png', 'jpg', 'jpeg', 'gif', 'webp'],
      headerRowStyle: { color: '#333333' },
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
      dialogVisible: false,
      dialogLoading: false,
      previewVisible: false,
      form: {},
      cloneDeepForm: {},
      rules: {
        bankName: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        cardNumber: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { validator: (rule, v, cb) => cb(v.length < 6 || v.length > 30 ? '输入账号长度范围必须在6~30之间' : void 0) },
        ],
        cardholder: [{ required: true, message: '请输入开户名', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    renderHeader(h, { column }) {
      const [label1, label2] = column.label.split('、');
      return h('div', {}, [h('p', {}, label1), h('p', {}, label2)]);
    },
    isNotImgFile(url) {
      if(!url){
        return true;
      }
      return this.whiteList.indexOf(url.substring(url.lastIndexOf('.') + 1)) === -1;
    },
    // 查看附件
    openAttachment(list) {
      this.attachmentJsonList = list;
      this.previewVisible = true;
    },
    init() {
      this.initFormData();
    },
    initFormData() {
      this.cloneDeepForm = this.form = {
        id: '',
        bankName: '',
        bankCode: '',
        cardNumber: '',
        cardholder: '',
        attachmentJson: [],
      };
    },
    openDialog(row) {
      this.dialogVisible = true;
      this.dialogLoading = true;
      this.form = {
        ...row,
        attachmentJson: row.attachmentJsonList.map((item) => ({
          url: item
        })),
      };
      this.cloneDeepForm = cloneDeep(this.form);
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    closeDialog() {
      this.$refs['dialogForm'].resetFields();
    },
    onSubmit() {
      this.$refs['dialogForm'].validate(valid => {
        if (!valid) return false;
        this.dialogLoading = true;
        const { attachmentJson } = this.form;
        updateBankCard({
          ...this.form,
          attachmentJsonList: attachmentJson.map(i => i.url),
        })
          .then(() => {
            this.$parent.editEnterprise();
            this.dialogVisible = false;
            this.$message.success('操作成功');
          })
          .finally(() => {
            this.dialogLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-img {
  display: block;
  height: 100%;
  width: auto;
  margin: auto;
  object-fit: contain;
}
.file-link{
  color: #AB0033;
  text-decoration: underline;
}
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
